import { h, tag } from "omi";

import { PureComponent } from "@/utils/pureComponent";
import { getFromQueryString } from "@/utils/url";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";

type Props = {};

@tag("nw-impersonation-banner")
export class NWImpersonationBanner extends PureComponent<Props> {
  static css = [tailwind];

  render() {
    if (Store.features.isAdmin && Store.app.isImpersonating.value) {
      return (
        <div class="bg-error-50 sticky top-0 p-1 z-50">
          <h4 class="text-error-600 text-center">
            You are currently impersonating:{" "}
            <strong>{getFromQueryString("userId") || getFromQueryString("userEmail")}</strong>
          </h4>
        </div>
      );
    }

    return null;
  }
}
