import { bind, Component, h, tag } from "omi";
import isEmpty from "lodash-es/isEmpty";

import { NWToolbar } from "@/pages/project/features/toolbar";
import { Project } from "@/api/models/project";
import { NWToastManager, triggerToast } from "@/components/base/nw-toast";
import { NWModalProvider } from "@/components/modals";
import { NWAuthManager } from "@/components/nw-auth-manager";
import { NWImpersonationBanner } from "@/components/nw-impersonation-banner";
import { AUTH_LOGGED_IN_TOAST_TRIGGER, ONBOARDING_HIDE_BG, TOOLBAR_SHOW_EXPLORE } from "@/globals/storageKeys";
import { ErrorService } from "@/services/errorService";
import { PurchaseNotificationService } from "@/services/purchaseNotificationService";
import { getFromQueryString } from "@/utils/url";
import { Track } from "@/api";
import { openModal } from "@/components";
import { Store } from "@/store";
import { tailwind } from "@/tailwind";
import { isMobileDevice } from "@/utils";

import globalStyles from "./globals/styles.css?inline";
import styles from "./app.css?inline";
import { initRouter, isRouteActive } from "./router";

type Props = {
  project: Required<Project>;
  selectedTrack?: Track["id"];
};

@tag("project-app")
export default class extends Component<Props> {
  static css = [tailwind, globalStyles, styles];
  static propTypes = {
    project: Object,
    selectedTrack: String,
  };

  private purchaseNotificationService: PurchaseNotificationService | undefined;

  install() {
    this.attachShadow({ mode: "open" });
    Store.project.setProject(this.props.project);
    if (this.props.selectedTrack) {
      Store.project.setSelectedTrack(this.props.selectedTrack);
    }
  }

  installed() {
    if (Store.user.isLoggedIn.value) {
      ErrorService.setUser(Store.user.info.value?.email);
    }

    initRouter(this.shadowRoot?.getElementById("router-outlet") as HTMLElement);
  }

  @bind
  private handleMobileExperience() {
    const hasNotSelectedTopics = isEmpty(Store.user.preferences.selectedCategories.value);
    const shouldTriggerLoggedInToast = getFromQueryString(AUTH_LOGGED_IN_TOAST_TRIGGER, true);
    const viewingPortfolio = isRouteActive("portfolio");

    if (Store.user.isLoggedIn.value) {
      // Portfolio is public and does not force users to login or show the mobile to desktop modal.
      if (Store.features.hasFeature("projects.navV2") && !viewingPortfolio) {
        openModal("mobileLock", {});
        return;
      }

      if (shouldTriggerLoggedInToast && hasNotSelectedTopics && !viewingPortfolio) {
        setTimeout(() => {
          Store.app.toggleNav(true, "mobile-first-time");
        }, 100);
      } else if (shouldTriggerLoggedInToast) {
        setTimeout(
          () =>
            triggerToast({
              icon: "check-circle-outline-green",
              message: "You're logged in! Let's learn 🎉",
              position: "top",
            }),
          500,
        );
      }
    } else {
      // Force login for mobile users
      if (!viewingPortfolio) {
        openModal("login", {});
      }
    }
  }

  @bind
  private handleDesktopExperience() {
    const shouldTriggerLoggedInToast = getFromQueryString(AUTH_LOGGED_IN_TOAST_TRIGGER, true);
    if (shouldTriggerLoggedInToast) {
      setTimeout(
        () =>
          triggerToast({
            icon: "check-circle-outline-green",
            message: "You're logged in! Let's learn 🎉",
            position: "top",
          }),
        500,
      );
    }

    /**
     * Handles the post onboarding experience once users have logged in.
     */
    if (Store.features.hasFeature("projects.navV2") && Store.user.isLoggedIn.value) {
      getFromQueryString(ONBOARDING_HIDE_BG, true);

      if (getFromQueryString(TOOLBAR_SHOW_EXPLORE, true) === "true") {
        setTimeout(() => Store.app.showToolbarView("directory"), 600);
      }
    }
  }

  ready() {
    if (isMobileDevice()) {
      this.handleMobileExperience();
    } else {
      this.handleDesktopExperience();
    }

    if (Store.features.hasFeature("projects.payments")) {
      import("@/services/purchaseNotificationService").then(({ PurchaseNotificationService }) => {
        this.purchaseNotificationService = new PurchaseNotificationService();
        this.purchaseNotificationService.process();
      });
    }

    import("@/services/refreshService").then(({ RefreshService }) => {
      const refreshService = new RefreshService();
      refreshService.startMonitoring();
    });
  }

  private isRootPath() {
    try {
      const url = new URL(window.location.href);
      return url.pathname === "/" || url.pathname === "";
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  render() {
    const isImpersonating = Store.app.isImpersonating.value;
    const isOnboarding = !Store.user.isLoggedIn.value && this.isRootPath();

    return (
      <div>
        {Store.features.hasFeature("projects.navV2") && <NWAuthManager withUI={false} />}
        <NWModalProvider />
        <NWToastManager />
        {isImpersonating && <NWImpersonationBanner />}

        <div id="router-outlet"></div>

        <div>{!isOnboarding ? <NWToolbar type="uncontrolled" /> : <div />}</div>
      </div>
    );
  }
}
